<template>
  <div :class="getClass" @click="backToTop">
    <svg class="icon" aria-hidden="true">
      <use xlink:href="#icon-xiaohuojian-b"></use>
    </svg>
  </div>
</template>

<script>
import BackTop from "common/backToTop.js";

export default {
  name: "BackTop",
  data() {
    return {
      showMe: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      // 滚动事件
      let html = document.documentElement;
      if (html.scrollTop >= 1000) {
        //当滚动高度大于等于500返回顶部出现
        this.showMe = true;
      } else {
        this.showMe = false;
      }
    });
  },
  methods: {
    backToTop() {
      if (this.showMe) {
        BackTop();
      }
    },
  },
  computed: {
    getClass() {
      if (this.showMe) {
        return "back-to-top animate__animated animate__fadeInUp";
      } else {
        return "back-to-top animate__animated animate__fadeOutDown";
      }
    },
  },
};
</script>

<style scoped>
.back-to-top {
  width: 62px;
  height: 80px;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 99;
}
.back-to-top svg {
  width: 100%;
  height: 100%;
}
</style>